import Logger from '@nematis/js-snippets/assets/js/logging/debug-logger'

const nematisUncodeAI = window.nematisUncodeAI || {}

document.addEventListener('DOMContentLoaded', () => {
  if ('MutationObserver' in window) {
    const timeout = 250
    const adaptiveImagesCounts = []
    const requiredSimilarCounts = 5
    const intervalId = setInterval(() => {
      const adaptiveImages = document.querySelectorAll('.adaptive-async, .adaptive-fetching, .async-done')
      if (adaptiveImagesCounts.length >= requiredSimilarCounts) {
        const filtered = adaptiveImagesCounts.slice(-requiredSimilarCounts)
        if (!filtered.some((count) => count !== adaptiveImages.length)) {
          Logger.log(`Nematis Uncode AI: found ${JSON.stringify(adaptiveImages.length)} adaptive images on ${requiredSimilarCounts} consecutive tests.`)
          processAdaptiveImages(adaptiveImages)
          clearInterval(intervalId)
        }
      }

      adaptiveImagesCounts.push(adaptiveImages.length)
    }, timeout)
  }
})

function processAdaptiveImages(adaptiveImages) {
  const minSizeRegex = new RegExp(nematisUncodeAI.uncode_ai_file_pattern)
  adaptiveImages.forEach((adaptiveImageEl, index) => {
    // image already contains async-done class: remove it and add it back a few ms later
    // that way it will properly trigger our observer callback
    if (adaptiveImageEl.classList.contains('async-done')) {
      const timeout = 5 * (index + 1)
      adaptiveImageEl.classList.remove('async-done', 'async-blurred')
      setTimeout(() => adaptiveImageEl.classList.add('async-done'), timeout)
    }
  })

  adaptiveImages.forEach((adaptiveImageEl) => {
    // track class list changes to determine when final image is supposedly loaded
    const mutationObserver = new MutationObserver(() => {
      if (adaptiveImageEl.classList.contains('async-done')) {
        // disconnect observer as soon as image is loaded to prevent memory leaks
        mutationObserver.disconnect()

        // check if this image is invalid and potentially trigger our fix when all images are processed
        checkAdaptiveImage(adaptiveImageEl, adaptiveImages, minSizeRegex)
      }
    })

    mutationObserver.observe(adaptiveImageEl, {
      attributes: true,
      attributeFilter: ['class']
    })
  })
}

function checkAdaptiveImage(adaptiveImageEl, adaptiveImages, minSizeRegex) {
  // check if the image src still contains "-uai-258x" pattern to determine if it failed to load
  const imageSrc = adaptiveImageEl.src || adaptiveImageEl.getAttribute('style')
  if (minSizeRegex.test(imageSrc)) {
    // mark this adaptive image as invalid
    adaptiveImageEl.classList.add(`${nematisUncodeAI.invalid_image_class}`)
  }

  // only trigger our fix once all adaptive images have been potentially loaded
  const asyncDoneImages = document.querySelectorAll('.async-done')
  if (asyncDoneImages.length === adaptiveImages.length) {
    maybeReloadAdaptiveImages()
  }
}

function maybeReloadAdaptiveImages() {
  // prepare invalid adaptive images for a new processing
  const invalidImages = document.querySelectorAll(`.${nematisUncodeAI.invalid_image_class}`)
  if (invalidImages.length) {
    Logger.log(`Nematis Uncode AI: found ${invalidImages.length} potentially invalid adaptive images.`)

    invalidImages.forEach((invalidImageEl) => {
      invalidImageEl.classList.remove(`${nematisUncodeAI.invalid_image_class}`)
      invalidImageEl.classList.add('adaptive-async', `${nematisUncodeAI.processed_image_class}`)
    })

    // call UNCODE function if possible
    if (typeof UNCODE !== 'undefined' && typeof UNCODE.adaptive === 'function') {
      Logger.log('Nematis Uncode AI: calling UNCODE.adaptive() function...')
      setTimeout(UNCODE.adaptive(), 50)
    }
  }
}
